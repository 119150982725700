import Image from "next/image";
import PhoneInput from "react-phone-number-input";
import BaseButton from "../../components/button/BaseButton";
import useLoginLogic from "../../hooks/useLoginLogic";
import withAuth from "../../hocs/AuthHoc";
import { useContext } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import { useLanguage } from "../../hooks/useLanguage";

function Login() {
  const {
    formLogin,
    loading,
    phoneNumberInternational,
    countExpired,
    countSend,
    titleOfSendOtp,
    errorPhoneNumber,
    isLoginDisabled,
    otpCode,
    t,
    onChangeOtpInput,
    onPhoneChange,
    onRequestSendOTP,
    handleBlur,
    formatTime,
    onPressBtnLogin,
    onRedirectHome,
  } = useLoginLogic();

  const { isLoadingAuth } = useContext(AuthContext);

  useLanguage();

  if (isLoadingAuth) return null;

  return (
    <div className="flex h-[100vh] px-4 justify-center items-center md:px-16 md:py-36">
      <div
        className="flex flex-col items-center justify-center w-full px-4 py-10 bg-white rounded-lg md:px-10 lg:w-1/2"
        id="container"
      >
        <div className="flex flex-col items-center w-full gap-4">
          <div className="flex flex-col items-center gap-2">
            <Image
              src={"/images/locate_logo_new.png"}
              alt="locate_logo"
              width={126}
              height={32}
              onClick={onRedirectHome}
              className="cursor-pointer"
            />
            <p className="text-purple-800 text-title-1 font-bold md:text-3.5xl">
              {t("login.title")}
            </p>
          </div>
          <div className="flex flex-col w-full gap-6">
            <RowInput
              label={t("phoneNumber")}
              name={"phoneNumber"}
              type={"phone"}
              placeholder={t("phone")}
              onChange={onPhoneChange}
              value={phoneNumberInternational}
              onClick={onRequestSendOTP}
              disabled={
                phoneNumberInternational === undefined ||
                phoneNumberInternational === "" ||
                countSend !== 0
                  ? true
                  : false
              }
              title={
                countExpired > 0 && countSend === 1
                  ? formatTime(countExpired)
                  : titleOfSendOtp
              }
              loading={loading}
              error={errorPhoneNumber}
              onBlur={handleBlur}
            />

            <RowInput
              label={t("login.verificationCode")}
              type={"text"}
              name={"code"}
              placeholder={t("login.otp")}
              onChange={onChangeOtpInput}
              value={otpCode}
              error={formLogin.touched?.code && formLogin.errors?.code}
            />
          </div>
          <div className="flex flex-col items-center w-full gap-4">
            <BaseButton
              isLoading={loading}
              sizeButton="md"
              disable={isLoginDisabled}
              onClick={onPressBtnLogin}
              title={t("login.login")}
              textClass={`text-body-2 font-bold ${
                isLoginDisabled ? "text-philippine_silver" : "text-white"
              } md:text-body-1`}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default withAuth(Login);

export const RowInput = ({
  label,
  name,
  type,
  placeholder,
  onChange,
  value,
  className,
  error,
  onClick,
  disabled,
  title,
  loading,
  onBlur,
}) => {
  return (
    <div className={className}>
      <div className="flex flex-col w-full gap-2 ">
        <div className="flex gap-1">
          <label className="font-bold text-body-2 text-fourth-100 md:text-body-1">
            {label}
            <span className="text-red_primary">*</span>
          </label>
        </div>
        <div
          className={`relative flex flex-row w-full ${
            name === "phoneNumber" ? "ltr:pr-2 rtl:pl-2" : ""
          }`}
          id="wrapperPhoneInput"
        >
          {name === "phoneNumber" ? (
            <PhoneInput
              name={name}
              type={type}
              className={`w-full ltr:rounded-tr-none ltr:rounded-br-none bg-white p-3 rounded-lg border ltr:border-r-0 border-solid text-right ${
                error ? "border-red_primary" : ""
              } border-fourth-300 outline-none text-primary-500 text-body-2 placeholder-fourth-300 md:text-body-1`}
              placeholder={placeholder}
              onChange={onChange}
              value={value}
              countries={[
                "SA",
                "KW",
                "BH",
                "AE",
                ...(process.env.NODE_ENV !== "production" ? ["VN"] : []),
              ]}
              defaultCountry="SA"
              international
              countryCallingCodeEditable={false}
            />
          ) : (
            <input
              name={name}
              type={type}
              className={`w-full bg-white p-3 rounded-lg border border-solid ${
                error && onBlur ? "border-red_primary" : ""
              } border-fourth-300 outline-none text-primary-500 text-body-2 placeholder-fourth-300 md:text-body-1`}
              placeholder={placeholder}
              onChange={onChange}
              value={value}
              onBlur={onBlur}
            />
          )}

          {name === "phoneNumber" && (
            <div
              className="absolute h-full ltr:right-0 rtl:left-0"
              id="sendOtp"
            >
              <BaseButton
                sizeButton="sm"
                title={title}
                className={`  font-bold ${
                  disabled && "text-philippine_silver"
                }`}
                onClick={onClick}
                disable={disabled}
                isLoading={loading}
              />
            </div>
          )}
        </div>
      </div>
      {error && (
        <p className="absolute text-body-3 text-red_primary">{error}</p>
      )}
    </div>
  );
};
