import { useRouter } from "next/router";
import { useContext } from "react";
import { AuthContext } from "../contexts/AuthContext";

const withAuth = (Component) => {
  const Auth = (props) => {
    const { user, isLoadingAuth } = useContext(AuthContext);
    const router = useRouter();
    if (isLoadingAuth) return;

    if (
      !user &&
      (router.pathname === "/my-ticket" ||
        router.pathname === "/payment-response" ||
        router.pathname === "/profile-user-setting")
    ) {
      router.push("/login");
    } else if (
      user &&
      (router.pathname === "/login" || router.pathname === "/register")
    ) {
      router.push("/");
    }

    return <Component {...props} />;
  };

  if (Component.getInitialProps) {
    Auth.getInitialProps = Component.getInitialProps;
  }

  return Auth;
};

export default withAuth;
