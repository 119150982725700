import { toast } from "react-toastify";

export const showToast = ({ type, message }) => {
  toast(message, {
    position: "bottom-left",
    type,
    autoClose: 3000,
    closeOnClick: true,
  });
};
// const type = success || error;
