import { useFormik } from "formik";
import { useRouter } from "next/router";
import { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { parsePhoneNumber } from "react-phone-number-input";
import { toast } from "react-toastify";
import * as yup from "yup";
import axiosInstance from "../api/api_instance";
import { requestSendOtpAPI, verifyOptApi } from "../api/auth.api";
import { getUserApi } from "../api/user.api";
import { AuthContext } from "../contexts/AuthContext";
import { setUserInLocalStorage } from "../services/authServices";
import { showToast } from "../utils/showToast";

const OTP_LENGTH = 4;

const useLoginLogic = () => {
  const { t } = useTranslation();
  const router = useRouter();
  const expiredTime = 59000;
  const { setUser } = useContext(AuthContext);

  const [loading, setLoading] = useState(false);
  const [phoneNumberInternational, setPhoneNumberInternational] = useState("");
  const [otpCode, setOtpCode] = useState("");
  const [errorPhoneNumber, setErrorPhoneNumber] = useState(false);
  const [titleOfSendOtp, setTitleOfSendOtp] = useState(t("login.sendOtp"));
  const [countSend, setCountSend] = useState(0);
  const [countExpired, setCountExpired] = useState(expiredTime);
  const [step, setStep] = useState(1);

  //countryCode and phoneNumber send to API
  const formatPhoneNumber = phoneNumberInternational?.length
    ? parsePhoneNumber(phoneNumberInternational)
    : "";
  const countryCode = formatPhoneNumber?.country;
  const phoneNumber = formatPhoneNumber?.number;

  const formatOtpCode = parseInt(otpCode.trim(), 10);

  const onGetProfile = async () => {
    try {
      const responseUser = await getUserApi();
      setUser(responseUser?.data);
      setUserInLocalStorage(responseUser?.data);

      return responseUser;
    } catch (error) {}
  };

  const onPressBtnLogin = useCallback(async () => {
    const prePageEvent = router.query?.event;

    setLoading(true);

    try {
      const response = await verifyOptApi(
        phoneNumber,
        formatOtpCode,
        countryCode
      );

      axiosInstance.setToken(response.data?.accessToken);

      if (response?.data?.isVerify) {
        onGetProfile();
        showToast({ message: t("login_success"), type: "success" });
        if (prePageEvent) {
          return router.replace(prePageEvent);
        }
        return router.replace("/home");
      }
      if (step === 2) {
        router.push({
          pathname: "/foodcourt-event-register",
          query: {
            phoneNumber: phoneNumber,
            accessToken: response.data?.accessToken,
            event: prePageEvent,
          },
        });
      } else
        router.push({
          pathname: "/update-profile",
          query: {
            phoneNumber: phoneNumber,
            accessToken: response.data?.accessToken,
            event: prePageEvent,
          },
        });
    } catch (error) {
      switch (error?.response?.data?.status) {
        case 422:
          toast.error(t("invalid_otp"));
          return;

        default:
          toast.error(t("invalid_otp"));
      }
    } finally {
      setLoading(false);
    }
  }, [countryCode, formatOtpCode, phoneNumber, router, t]);

  const formLogin = useFormik({
    initialValues: {
      code: "",
    },
    validationSchema: yup.object({
      code: yup.string().min(0, t("validate.code_empty")),
    }),

    onSubmit: onPressBtnLogin,
  });

  const isLoginDisabled =
    !phoneNumberInternational?.length ||
    otpCode?.length < OTP_LENGTH ||
    loading ||
    countSend < 0;

  const isEventLoginDisabled = !phoneNumberInternational?.length || loading;

  const onChangeOtpInput = useCallback((event) => {
    setOtpCode(event.target.value);
  }, []);

  const handleBlur = () => {
    const formatPhoneNumber = parsePhoneNumber(
      phoneNumberInternational.toString()
    );

    if (!formatPhoneNumber || !formatPhoneNumber.isValid()) {
      setErrorPhoneNumber(true);
    } else {
      setErrorPhoneNumber(false);
    }
  };

  const onPhoneChange = (value) => {
    setPhoneNumberInternational(value);
  };

  const onRequestSendOTP = useCallback(async () => {
    setCountExpired(expiredTime);

    await requestSendOtpAPI(phoneNumber, countryCode)
      .then((response) => {
        if (response.status === 200 || response?.status === 201) {
          setCountSend(1);
          toast.success(t("send_otp_success"));
          setStep(2);
        }
      })
      .catch((err) => {
        if (err?.response?.status === 400) {
          toast.error(t("phone_invalid"));
          return;
        }
        toast.error(err.message);
      });
  }, [countryCode, phoneNumber, t]);

  const formatTime = (time) => {
    const minutes = Math.floor(time / (60 * 1000));
    const seconds = Math.ceil((time / 1000) % 60);

    return `${minutes < 10 ? "0" + minutes : minutes} : ${
      seconds < 10 ? "0" + seconds : seconds
    }`;
  };

  useEffect(() => {
    if (countSend !== 0) {
      const intervalId = setInterval(() => {
        const now = Date.now();
        const timeDifference = Math.max(0, countExpired - (now - startTime));

        setCountExpired(timeDifference);
      }, 1000);

      const startTime = Date.now();

      return () => {
        clearInterval(intervalId);
      };
    }
  }, [countExpired, countSend]);

  useEffect(() => {
    if (countExpired <= 0 && countSend !== 0) {
      setTitleOfSendOtp(t("resend"));
      setCountSend(0);
    }
  }, [countExpired, countSend, t]);

  const onRedirectHome = () => [router.push("/")];

  return {
    formLogin,
    loading,
    phoneNumberInternational,
    countSend,
    countExpired,
    titleOfSendOtp,
    errorPhoneNumber,
    isLoginDisabled,
    otpCode,
    t,
    onChangeOtpInput,
    onPhoneChange,
    onRequestSendOTP,
    handleBlur,
    formatTime,
    onPressBtnLogin,
    onRedirectHome,
    isEventLoginDisabled,
    setStep,
    step,
  };
};

export default useLoginLogic;
