import axios from "axios";
import { API_VERSION, BASE_URL, FULL_BASE_URL } from "../constant/server";
import axiosInstance from "./api_instance";

const headers = {
  "Content-Type": "application/json;charset=UTF-8",
  "Access-Control-Allow-Origin": "*",
};
export const registerApi = ({ fullName, email, city, nationality }) => {
  return axiosInstance.patch(
    `${FULL_BASE_URL}users/verify`,
    {
      email,
      city,
      fullName,
      nationality,
    },
    {
      headers: headers,
    }
  );
};

export const requestSendOtpAPI = (recipients, countryCode) => {
  return axios.post(
    `${FULL_BASE_URL}sms-otps`,
    {
      recipients,
      countryCode,
    },
    {
      headers: headers,
    }
  );
};

export const verifyOptApi = (recipients, code, countryCode) => {
  return axios.post(
    `${BASE_URL}${API_VERSION}sms-otps/verify`,
    {
      recipients,
      code,
      countryCode,
    },
    {
      headers: headers,
    }
  );
};
